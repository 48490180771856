import 'devextreme/dist/css/dx.light.css';
import React, { Suspense } from 'react';


import Fonts from "@churchofjesuschrist/eden-fonts";
import EdenNormalize from "@churchofjesuschrist/eden-normalize";
import { Helmet } from 'react-helmet';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

import ErrorBoundary from './components/generic/error-boundary/error-boundary';
import LoadingSpinner from './components/generic/loading-spinner/loading-spinner';
import MasterLayout from './components/global-layout/master-layout/master-layout';

//takes up a min height so things don't bounce around as suspense resolves between spinner and content
const BouncePreventer = styled.div`
min-height:216px;
`


const App = ({children}) => (
  <>
    <Helmet>
      <Fonts />
    </Helmet>
    <EdenNormalize />
    <MasterLayout>
      <BouncePreventer>
        <Suspense fallback={<LoadingSpinner large/>}>
          <ErrorBoundary>
            <Outlet/>
          </ErrorBoundary>
        </Suspense>
      </BouncePreventer>
    </MasterLayout>
  </>
);

export {BouncePreventer}

export default App;
