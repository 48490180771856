import React from "react";

import {
  spacing8,
  serif,
  grey60,
} from "@churchofjesuschrist/eden-style-constants";
import { WorkforceHeader } from "@churchofjesuschrist/eden-workforce-header";
import { useNavigate } from 'react-router-dom';
import styled from "styled-components";

import useTranslations from "../../../utils/use-translations";
import TopBarMenus from "../top-bar-menus/top-bar-menus";

const ChurchNameHolder = styled.div`
padding:${spacing8};
text-align:center;
font-family:${serif};
a{
  text-decoration:none;
  color:${grey60};
  &:hover{
    color:${grey60}
  }
}
`;

const stringGroups = [
  {
    name:"shared",
    keys:[
      "institute",
      "churchName"
    ]
  }
];

const TopBar = ({flagService}) => {
  const {managedStrings} = useTranslations(stringGroups);

  const navigate = useNavigate();
  const onHomeClicked = (e) => {
    e.preventDefault()
    window.scrollTo(0, 0);
    navigate("/");
  }

  return (
    <div>
      <ChurchNameHolder><a href="http://churchofjesuschrist.org">{managedStrings.churchName}</a></ChurchNameHolder>
      <WorkforceHeader
        name={<a href="/" onClick={onHomeClicked}>{managedStrings.institute}</a>}
        tools={<TopBarMenus flagService={flagService}/>} />
    </div>
  );
};

export default TopBar;
